@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@font-face {
    font-family: "Corben";
    src: url('./assets/fonts/Corben/Corben-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: "Corben";
    src: url('./assets/fonts/Corben/Corben-Regular.ttf');
    font-weight: 500;
}

@font-face {
    font-family: "DM Sans";
    src: url('./assets/fonts/DM_Sans/DMSans-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: "DM Sans";
    src: url('./assets/fonts/DM_Sans/DMSans-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: "DM Sans";
    src: url('./assets/fonts/DM_Sans/DMSans-Regular.ttf');
    font-weight: 400;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
